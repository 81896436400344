<!-- Facturas de la remesa seleccionada -->

<template>
  <div class="cobrosPagosFrasRemesas" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>

        <template v-slot:controles="{}">
          <div class="columna">

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">
              <div class="contenedor">

                <!-- Contenido -->
                <div class="columna" style="width:500px;padding-top:10px">

                  <!-- Grid -->
                  <baseGridMD
                    :store="storeName"
                    :headers="headerGrid"
                    :items-per-page=-1
                    dense
                    fixed-header
                    hide-default-footer
                    height="300px"
                    @onEvent="execAccion">
                  </baseGridMD>

                </div>
              </div>
            </v-sheet>
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import dualTemplate from "@/components/dualTemplate";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, dualTemplate },
    props: {
      recordsFras: { type:[Array, Object], default:null },
    },

    data() {
      return {

        stIni: {
          api: "cobrosPagosFrasRemesa",
          titulo:"·Facturas",
          name: "cobrosPagosFrasRemesa",
          mView: '',
          pView:[]
        },

      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        this.headers = [{
          name:[],
          fields:[
            { text: "Factura", value: "fra_doc", width: "25%" },
            { text: "Fecha", value: "fra_fh", width: "30%" , filtro:'fecha'},
            { text: "Expte", value: "control", width: "15%" },
            { text: "Importe", value: "imp", width: "30%" }
          ]}
        ];

        this.headerGrid= this.headerSet();
      },


      iniDataApi() {
        this.apiArgs.sch= null;
      },


      callRecordsGet() {
        this.$store.commit(this.storeName + '/recordSet', this.recordsFras);
      },


      // obtengo los registros a mostrar
      /* ini_component() {
        // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
        this.$store.commit(this.stName + '/update_records', this.records);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: this.records });
      }, */


      //
      /* errorRecords() {
        this.$root.$alert.open('Debe seleccionar una Remesa', 'error');
      } */

    },


    computed: {

      // devuelve schema particular
      sch() {
        return {}
      },

    }

  }
</script>
